<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col md="6">
        <b-form-group>
          <h5>Range Dates</h5>
          <flat-pickr
            v-model="rangeDate"
            :config="{ mode: 'range', defaultDate: defaultDate}"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <card-statistics-card
          v-if="!loading"
          :range-date="rangeDate"
        />
      </b-col>
    </b-row>
    <div class="v-row">
      <div class="v-col-md-6">
        <chart-cities
          v-if="!loading"
          :range-date="rangeDate"
        />
        <chart-areas
          v-if="!loading"
          :range-date="rangeDate"
        />
        <chart-shipments
          v-if="!loading"
          :range-date="rangeDate"
        />
      </div>
      <div class="v-col-md-6" />
    </div>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { kFormatter } from '@core/utils/filter'
import CardStatisticsCard from '@/views/dashboardComponents/cardStatisticsCard.vue'
import ChartCities from '@/views/dashboardComponents/chartCities.vue'
import ChartAreas from '@/views/dashboardComponents/chartAreas.vue'
import chartShipments from '@/views/dashboardComponents/chartShipments.vue'
import flatPickr from 'vue-flatpickr-component'
import {
  BCol,
  BFormGroup,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ChartCities,
    ChartAreas,
    chartShipments,
    CardStatisticsCard,
    flatPickr,
    BFormGroup,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      rangeDate: null,
      defaultDate: null,
      loading: true,
      users_and_clients: [],
      users_list: [],
      modal_title: '',
    }
  },
  created() {
    // this.getUsersAndClients()
  },
  mounted() {
    // this.funcInterval = setInterval(() => this.getUsersAndClients(), 5000)
    const to = moment().format('YYYY-MM-DD')
    const from = moment(to).subtract(1, 'month').format('YYYY-MM-DD')
    this.defaultDate = [from, to]
    this.rangeDate = this.defaultDate
    this.loading = false
  },
  beforeDestroy() {
    // clearInterval(this.funcInterval)
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
