<template>
  <b-card
    class="card-statistics"
    no-body
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!--        Updated 1 month ago-->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.subtitle"
          :class="item.customClass"
          class="mb-2 mb-md-1"
          md="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                :variant="item.color"
                size="48"
              >
                <feather-icon
                  :icon="item.icon"
                  size="24"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: [
    'rangeDate',
  ],
  data() {
    return {
      statisticsItems: [],
      // funcInterval: ''
    }
  },
  watch: {
    rangeDate() {
      this.getStatistics()
    },
  },
  mounted() {
    this.getStatistics()
    // this.funcInterval = setInterval(() => this.getStatistics(), 10000)
  },
  beforeDestroy() {
    clearInterval(this.funcInterval)
  },
  methods: {
    async getStatistics() {
      let rangeDates = null
      if (this.rangeDate && this.rangeDate.includes('to')) {
        rangeDates = this.rangeDate.split(' to ')
        await axios.get('/index', {
          params: {
            from: rangeDates[0],
            to: rangeDates[1],
          },
        }).then(resp => {
          this.statisticsItems = resp.data
        })
      }
    },
  },
}
</script>
