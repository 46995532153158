<!--<template>
  <div>
    <canvas id="cities-chart" />
  </div>
</template>-->
<script>
import { Bar } from 'vue-chartjs'
import axios from '@axios'

export default {
  name: 'CitiesChart',
  extends: Bar,
  props: [
    'rangeDate',
  ],
  // components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  watch: {
    async rangeDate() {
      await this.getData()
      if (this.loaded) {
        this.renderChart(this.chartdata, this.options)
      }
    },
  },
  async mounted() {
    await this.getData()
    if (this.loaded) {
      this.renderChart(this.chartdata, this.options)
    }
  },
  methods: {
    async getData() {
      this.loaded = false
      let rangeDates = null
      if (this.rangeDate && this.rangeDate.includes('to')) {
        rangeDates = this.rangeDate.split(' to ')
        await axios.get('/citiesStatistics', {
          params: {
            rangeDate: rangeDates,
          },
        }).then(resp => {
          this.chartdata.labels = resp.data.labels
          this.chartdata.datasets = [
            {
              label: 'Top Cities',
              backgroundColor: '#f87979',
              data: resp.data.values,
            },
          ]
          this.loaded = true
        })
      }
    },
  },
}
</script>
